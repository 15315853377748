<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据看板</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <!-- <el-button type="primary" @click="add(null, false)">新增物流公司</el-button> -->
        </el-col>
      </el-row>
      
      <el-row style="margin-top: 10px;">
        <el-col style="display: flex;">
          <el-select v-model="companyIndex" placeholder="选择主体" class="search-select" @change="onCompanyChange">
            <el-option v-for="(item, index) in companys" :key="index" :label="item.name" :value="index" />
          </el-select>
          <el-select v-if="companys.length" v-model="params.appletIds" placeholder="选择小程序" multiple collapse-tags class="search-select" >

            <el-option v-for="item in companys[companyIndex].alipayApplets" :key="item.appletId" :label="item.businessName" :value="item.appletId" />
          </el-select>
          <el-input v-model="params.orderId" class="search-input" placeholder="订单号" clearable />
          <el-input v-model="params.goodsTitle" class="search-input" placeholder="商品名称" clearable />
          <el-input v-model="params.goodsId" class="search-input" placeholder="商品ID" clearable />
          <el-input v-model="params.goodsRemark" class="search-input" placeholder="商品描述" clearable />
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col style="display: flex;">
          <el-date-picker class="search-date" v-model="params.beginDate" type="date" placeholder="下单开始时间" value-format="yyyy-MM-dd" ></el-date-picker>
            <span style="line-height: 38px;margin: 0 5px;">-</span>
            <el-date-picker class="search-date" v-model="params.endDate" type="date" placeholder="下单结束时间" value-format="yyyy-MM-dd" >
          </el-date-picker>
          
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;display: flex;align-items: center;">
      <el-checkbox-group v-model="params.orderStatus">
        <el-checkbox v-for="item in statusList" :label="item.value">{{item.key}}</el-checkbox>
      </el-checkbox-group>
      <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
      <el-button size="mini" type="warning" @click="exportOrder">导出Excel</el-button>
      </el-row>
      <el-row style="margin-top: 10px;">
        实收{{truePaySum}}元，成本{{purchaseMoneySum}}元，利润{{profitMoneySum}}元。
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="appletName" label="小程序" />
        <el-table-column prop="orderId" label="订单号" />
        <el-table-column label="商品图片" width="80">
          <template slot-scope="scope">
            <img v-if="scope.row.goodsImg" :src="scope.row.goodsImg.split(',')[0]"
                 style="width: 60px; height: 60px; border: none" />
          </template>
        </el-table-column>
        <el-table-column prop="goodsTitle" label="商品名称" />
        <el-table-column prop="goodsId" label="商品ID" />
        <el-table-column prop="goodsRemark" label="商品描述" />
        <el-table-column prop="payTime" label="下单时间" />
        <el-table-column prop="orderStatusName" label="订单状态" />
        <el-table-column prop="payMoneyTrue" label="商家实收" width="80" />
        <el-table-column prop="purchaseMoney" label="成本价" width="80" />
        <el-table-column prop="profitMoney" label="利润" width="80" />
      </el-table>
      <!-- 分页 -->
      <div class="Pagination">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="params.currentPage"
                       :page-sizes="[10, 30, 50, 100]"
                       :page-size="params.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>

  </div>
</template>

<script>
import qs from 'qs'
export default {

  data () {
    return {
      list: [],
      dialogVisible: false,
      params: {
        beginDate: '',
        endDate: '',
        appletIds: '',
        goodsTitle: '',
        goodsId: '',
        goodsRemark: '',
        currentPage: 1,
        pageSize: 50,
        subjectKey: '',
        orderStatus: []
      },
      statusList: [
        {key: '待发货', value: '9000'},
        {key: '已发货', value: '8888'},
        {key: '已完成', value: '9999'},
        {key: '申请退款(未发货)', value: '5555'},
        {key: '申请退款(已发货)', value: '4444'},
        {key: '已退款', value: '3333'},
        {key: '等待买家退货', value: '6666'},
        {key: '等待商家收货', value: '1111'},
        {key: '商家收到退货', value: '2222'},
      ],
      apps: [],
      companys: [],
      companyIndex: 0,
      total: 1,
      profitMoneySum : 0,
      purchaseMoneySum : 0,
      truePaySum : 0,
    }
  },
  created () {
    
    this.$api.appletsSubjects({}).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败')
        this.companys = res.data
        this.params.subjectKey = this.companys[0].subjectKey;
        this.getList();
    })
  },

  methods: {
    onCompanyChange() {
      this.params.appletIds=[]
      document.querySelectorAll('.el-tag').remove();
    },
    // 选中页面值
    handleSizeChange (newSize) {
      this.params.pageSize = newSize
      this.getList()
    },
    //当页面变动的时候
    handleCurrentChange (NewPage) {
      this.params.currentPage = NewPage
      this.getList()
    },
    search(){
      let {beginDate, endDate, appletIds} = this.params;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      // if(appletIds && appletIds.length){
      //   let subjectKeys = []
      //   appletIds.forEach(id => {
      //     const appInfo = this.apps.find(o => o.appletId === id);
      //     if(appInfo && !subjectKeys.includes(appInfo.subjectKey)){
      //       subjectKeys.push(appInfo.subjectKey)
      //       // this.params.appletId = id
      //     }
      //   })
      //   this.params.subjectKeys = subjectKeys;
      // }
      this.params.subjectKey = this.companys[this.companyIndex].subjectKey;
      this.params.currentPage = 1;
      this.getList();
    },
    getList () {
      if(this.params.beginDate) this.params.beginDate = `${this.params.beginDate} 00:00:00`;
      if(this.params.endDate) this.params.endDate = `${this.params.endDate} 23:59:59`;
      this.$api.getOrderListToPage(this.params).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败:' + res.message)
        
        this.list = res.data.orders.records
        this.total = res.data.orders.total
        this.profitMoneySum = res.data.profitMoneySum;
        this.purchaseMoneySum = res.data.purchaseMoneySum;
        this.truePaySum = res.data.truePaySum;
      })
    },
    // 监听页码大小 也就是下拉框
    handleSizeChange (newSize) {
      this.params.pageSize = newSize
      // 刷新列表
      this.getList()
    },

    // 监听当前页的转换 也就是点击数字
    handleCurrentChange (newPage) {
      this.params.currentPage = newPage
      // 刷新列表
      this.getList()
    },
    exportOrder(){
      let p = {...this.params};
      delete p.currentPage;
      delete p.pageSize;
      Object.keys(p).map(o => {
        if(!p[o]){
          delete p[o]
        }
      })
      let {beginDate, endDate} = p;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      window.open('/api/admin/alipay-order/orderOutExcel'+ "?" + qs.stringify(p))
      return;
    },
  },
}
</script>
<style lang="scss" >
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:180px;
    margin-right: 5px;
}
.search-select{
  width:220px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
.status-item{
  padding: 4px 6px;
  border: 1px solid #ddd;
  margin-left: 5px;
  border-radius: 5px;
  &.active{
    background: #1b9aee;
    border-color: #1b9aee;
    color: #fff;
  }
}
.detail-dialog{
  .el-dialog__body{
    padding-top: 0;
  }
}
</style>
